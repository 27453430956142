import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Since the debut of Apple Pay in October 2014, analysts have kept a watchful eye on this promising entrant in the mobile contactless payments field. Four months after the launch, consumer adoption of Apple Pay has reached 11 percent of cardholder households and 66 percent of iPhone 6 owners, according to research conducted by Phoenix Marketing International.`}</p>
    <p>{`Phoenix revealed that during the first four months, 82 percent of early adopters had linked credit cards to Apple Pay, 53 percent debit cards and 20 percent general purpose re-loadable prepaid cards. Entering month five, 31 percent of iPhone 6 owners who signed up for Apple Pay still had not attempted to link a card, and 3 percent who had made an attempt failed in the process, leaving room for more users to link cards in the future.`}</p>
    <p>{`Moving to the next stage in Apple Pay mobile commerce, 88 percent of consumers who set up an Apple mobile wallet within the first four months of launch went on to make in-store or in-app purchases, raising the conversion rate for a new mobile wallet scheme above 50 percent for the first time, according to analysts.`}</p>
    <p>{`Read the story at `}<a parentName="p" {...{
        "href": "http://www.greensheet.com/emagazine.php?story_id=4389"
      }}>{`GreenSheet.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      